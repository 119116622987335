import { fetchAPI } from '@/services/prismic';
import { camelize } from '@/scripts/camelize';

const getFooterContentQuery = `
query getAllFooterContent
  {
    allFooters {
      edges {
        node {
          items {
            footer_items {
              ... on Footer_item {
                title
                url
                type
                nav_links {
                  nav_link {
                    ... on Nav_links {
                      title
                      link
                      target
                      _meta {
                        id
                      }
                    }
                  }
                }
                social_networks {
                  social_network {
                    ... on Social_network_item {
                      title
                      icon
                      url
                    }
                  }
                }
                description
              }
            }
          }
        }
      }
    }
  }
`

export const fetchFooterContent = async previewData => {
  const data = await fetchAPI(getFooterContentQuery, {
    previewData,
  });

  return camelize(data);
};