import { viewPortTypes } from './actionTypes';

export const setMobile = () => {
  return (dispatch, _) => {
    dispatch({
      type: viewPortTypes.SET_MOBILE_VIEWPORT, 
    });
  };
};

export const setTablet = () => {
  return (dispatch, _) => {
    dispatch({
      type: viewPortTypes.SET_TABLET_VIEWPORT, 
    });
  };
};

export const setDesktop = () => {
  return (dispatch, _) => {
    dispatch({
      type: viewPortTypes.SET_DESKTOP_VIEWPORT, 
    });
  };
};
