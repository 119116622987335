import { fetchAPI } from '@/services/prismic';
import { camelize } from '@/scripts/camelize';

export const getSharedContentQuery = `
  query getSharedContent {
    allShareds {
      edges{
        node{
          cpi_logo {
            ...on Cpi_logo {
              title
              logo
            }
          }
          nav_links {
            nav_link {
              ...on Nav_links {
                title
                link
                _meta {
                  id
                }
              }
            }
          }
          social_network {
            ...on Social_network {
              social_networks {
                social_network {
                  ...on Social_network_item {
                    title
                    icon
                    url
                    _meta {
                      id
                    }
                  }
                }
              }
            }
          }
          footer {
            ...on Footer {
              items {
                footer_items {
                  ...on Footer_item {
                    title
                    url
                    type
                    nav_links {
                      nav_link {
                        ...on Nav_links {
                          title
                          link
                        }
                      }
                    }
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const fetchSharedContent = async previewData => {
  const data = await fetchAPI(getSharedContentQuery, {
    previewData,
  });

  return camelize(data);
};