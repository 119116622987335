import { viewPortTypes } from '../../actions/actionTypes';

const initialState = {
  isDesktop: false,
  isMobile: true,
  isTablet: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case viewPortTypes.SET_DESKTOP_VIEWPORT:
      return {
        ...state,
        isDesktop: true,
        isMobile: false,
        isTablet: false,
      };

    case viewPortTypes.SET_MOBILE_VIEWPORT:
      return {
        ...state,
        isDesktop: false,
        isMobile: true,
        isTablet: false,
      };

    case viewPortTypes.SET_TABLET_VIEWPORT:
      return {
        ...state,
        isDesktop: false,
        isMobile: false,
        isTablet: true,
      };

    default:
      return state;
  }
};
