import { useEffect } from 'react';
import Script from 'next/script'

// Redux
import { wrapper } from '../store';
import { setDesktop } from '@/store/actions/viewPortActions';

// Queries
import { fetchFooterContent } from '@/graphql/shared/footer';
import { fetchSharedContent } from '@/graphql/shared/';

function MyApp({
  Component,
  pageProps,
}) {
  useEffect(() => {
    try {
      const {
        pageGeneratedOn,
      } = pageProps;

      setTimeout(() => {
        try{
          if(window.location.hash) {
            const el = document.querySelector(window.location.hash);
            const offsetTop = el && el.offsetTop;
            scroll({
              top: offsetTop,
              behavior: 'smooth',
            });
          }
        }catch(e){
          if(!e.message.includes('Failed to execute \'querySelector\' on \'Document\'')){
            throw e;
          }
        }

        const relativeLinks = document.querySelectorAll('a[href*="[ru]"]');
        relativeLinks.forEach(link => {
          link.setAttribute('href', link.getAttribute('href').replace('https://[ru]', ''))
        });
        
      }, 1500);

      const generatedTime = Date.parse(pageGeneratedOn);
      const renderTime = new Date();

      const diffInSeconds = Math.floor(Math.abs(renderTime - generatedTime) / 1000);
      const formattedTimeDiff = `${Math.floor(diffInSeconds / 60)}:${`${diffInSeconds % 60}`.padStart(2, '0')}`;

      console.info(`Page generation time drift: ${formattedTimeDiff}s`);
      console.info(`Generated at ${pageGeneratedOn}`);
      console.info(`Received at ${renderTime.toISOString()}`);
    } catch (err) {
      console.error('Could not check page generation time drift');
      console.error(err);
    }
  }, []);

  return (
    <>

      <Script
        id="gtag-init"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          <!-- Google Tag Manager - Inicio -->
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NS4P57V');
          <!-- Google Tag Manager - Final -->
          `,
        }}
      />

      <Script
        id="fbpixel-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `

            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '296632900759465');
            fbq('track', 'PageView');

          `,
        }}
      />

      <Script id="hs-script-loader"  strategy='afterInteractive' src="https://js-na1.hs-scripts.com/8617308.js" />
      <Script id="hs-script-loader2" strategy='afterInteractive' src="https://js.hs-scripts.com/8617308.js" />

      <Component {...pageProps} />
      <style jsx global>{`
        * {
          box-sizing: border-box;
        }

        html {
          font-size: 16px;
          scroll-behavior: smooth;
        }

        @font-face {
          font-family: 'MontserratRegular';
          src: url('/assets/fonts/montserrat-regular.woff') format('woff'),
               url('/assets/fonts/montserrat-regular.ttf') format('truetype'),
               url('/assets/fonts/montserrat-regular.svg#Montserrat') format('svg');
          font-weight: normal;
          font-size: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'MontserratBold';
          src: url('/assets/fonts/Montserrat-Bold.ttf') format('truetype');
          font-size: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'MontserratSemiBold';
          src: url('/assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
          font-size: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'MontserratLight';
          src: url('/assets/fonts/Montserrat-Light.ttf') format('truetype');
          font-size: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'MontserratMedium';
          src: url('/assets/fonts/Montserrat-Medium.ttf') format('truetype');
          font-size: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'OpenSansRegular';
          src: url('/assets/fonts/open-sans-regular.woff') format('woff'),
            url('/assets/fonts/open-sans-regular.ttf') format('truetype'),
            url('/assets/fonts/open-sans-regular.svg#OpenSans') format('svg');
          font-weight: normal;
          font-size: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'OpenSansBold';
          src: url('/assets/fonts/OpenSans-Bold.ttf') format('truetype');
          font-size: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'OpenSansSemiBold';
          src: url('/assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
          font-size: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'RubikRegular';
          src: url('/assets/fonts/rubik-regular.woff') format('woff'),
            url('/assets/fonts/rubik-regular.ttf') format('truetype'),
            url('/assets/fonts/rubik-regular.svg#Rubik') format('svg');
          font-weight: normal;
          font-size: normal;
          font-display: swap;
        }

        body {
          font-family: 'OpenSansRegular';
          display: flex;
          flex-direction: column;
          justify-content: content;
          padding: 0;
          margin: 0;
          overflow: auto;
          color: #292929;
          background-color: #ffffff;
        }

        body strong {
          font-family: 'OpenSansBold';
        }

        body p strong a {
          font-family: 'OpenSansBold';
        }

        body::-webkit-scrollbar {
          width: 10px;
        }

        body::-webkit-scrollbar-track {
          background-color: #f1f1f1;
        }

        body::-webkit-scrollbar-thumb {
          background-color: #a1a1a1;
          border-radius: 10px;
          cursor: pointer;
        }

        body.nav-open {
          overflow: hidden;
        }

        body.nav-open::before {
          content: '';
          position: absolute;
          height: 1000vh;
          width: 100%;
          background: rgba(0, 0, 0, 0.4);
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        h1, h2, h3, h4, h5, h6 {
          margin-block-start: 0.3em;
          margin-block-end: 0.3em;
        }

        h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          color: inherit;
        }

        h1 {
          font-family: MontserratBold;
          font-size: 3rem;
          line-height: 3rem;
        }

        h2 {
          font-family: MontserratRegular;
          font-size: 2.5rem;
          line-height: 2.6rem;
        }

        h3 {
          font: normal normal normal 2rem/1.4063rem MontserratRegular;
        }

        h4 {
          font: normal normal normal 1.5rem/1.1719rem MontserratRegular;
        }

        h5 {
          font: normal normal normal 1.25rem/1.1719rem MontserratRegular;
        }

        h6 {
          font-family: MontserratRegular;
          font-size: 1.5rem;
          line-height: 2rem;
          margin: 3rem 0 2rem 0;
        }

        a, p, .body-text {
          font-family: MontserratRegular;
          font-size: 1rem;
          line-height: 1.5rem;
        }

        .body-text-large {
          font-size: 1.5rem;
          line-height: 1.8rem;
        }

        .body-text-small {
          font-size: 1rem;
          line-height: 1.4rem;
        }

        .body-text-small {
          font-size: 1rem;
          line-height: 1.4rem;
        }

        .text-center {
          text-align:center;
        }

        .text-justify {
          text-align: justify;
        }
        
        .cpi-monserrat {
          font-family: MontserratRegular;
        }

        .cpi-monserrat-bold {
          font-family: MontserratBold;
        }

        @media only screen and (min-width: 835px) {
          body p {
          }

          body.nav-open {
            overflow: auto;
          }

          body.nav-open::before {
            display: none;
          }

          h1 {
            font-family: MontserratBold;
            font-size: 3rem;
            line-height: 3.1rem;
          }

          h2 {
            font-family: MontserratRegular;
            font-size: 2.8rem;
            line-height: 2.9rem;
          }

          h3 {
            font-family: MontserratRegular;
            font-size: 2.3rem;
            line-height: 2.4rem;
          }

          h4 {
            font-family: MontserratRegular;
            font-size: 2rem;
            line-height: 2.3rem;
          }

          h5 {
            font-family: MontserratRegular;
            font-size: 1.6rem;
            line-height: 1.8rem;
          }

          h6 {
            font-family: MontserratRegular;
            font-size: 1.5rem;
            line-height: 1.7rem;
          }

          ul {
            font-family: MontserratRegular;
            font-size: 1rem;
            line-height: 1.5rem;
          }

          table {
            margin: 0 auto;
          }
          
          /* Default Table Style */
          table {
            color: #333;
            background: white;
            border: 1px solid grey;
            font-size: 12pt;
            border-collapse: collapse;
          }
          table thead th,
          table tfoot th {
            color: #777;
            background: rgba(0,0,0,.1);
          }
          table caption {
            padding:.5em;
          }
          table th,
          table td {
            padding: 1rem;
            border: 1px solid lightgrey;
          }
          table td p, table td span {
            margin-block-start: 0;
            margin-block-end: 0;
          }

          .fslightbox-container svg {
            width: 30px;
            height: 30px;
          }
        }
      `}</style>
    </>
  );
}

MyApp.getInitialProps = async ({
  Component,
  ctx,
}) => {
  const userAgent = ctx.req
    ? ctx.req.headers['user-agent']
    : navigator.userAgent;

  const mobileExpression =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/;
  if (!mobileExpression.test(userAgent)) {
    ctx.store.dispatch(setDesktop());
  }

  const {
    allShareds: {
      edges: [{
        node: sharedContent,
      } = {}] = [],
    } = {},
  } = await fetchSharedContent(ctx.previewData);

  const {
    allFooters: {
      edges: [{
        node: footerContent,
      } = {}] = [],
    } = {},
  } = await fetchFooterContent(ctx.previewData);

  const pageGetInitialPropsPromise = Component.getInitialProps?.(ctx) || {};
  const pageProps = await pageGetInitialPropsPromise;

  return {
    pageProps: {
      ...pageProps,
      sharedContent: {
        ...sharedContent,
        footer: footerContent,
      },
      pageGeneratedOn: (new Date()).toISOString(),
    },
  };
};

export default wrapper.withRedux(MyApp);
