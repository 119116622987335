// import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import {
  createStore, applyMiddleware 
} from 'redux';
import { createWrapper } from 'next-redux-wrapper';

import rootReducer from './reducers/rootReducer';

const makeStore = () =>
  createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export const wrapper = createWrapper(makeStore);
