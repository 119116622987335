import {
  ApolloClient, HttpLink, InMemoryCache 
} from '@apollo/client'
import * as prismic from '@prismicio/client'
import { gql } from 'graphql-tag';


const REPOSITORY = process.env.CPI_PRISMIC_REPOSITORY_NAME;
const API_TOKEN = process.env.CPI_PRISMIC_ACCESS_TOKEN;
const API_LOCALE = process.env.CPI_PRISMIC_REPOSITORY_LOCALE;

const prismicClient = prismic.createClient(
  prismic.getEndpoint(REPOSITORY),
  {
    accessToken: API_TOKEN,
  }
)

export const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: prismic.getGraphQLEndpoint(REPOSITORY),
    fetch: prismicClient.graphQLFetch,
    useGETForQueries: true,
  }),
  cache: new InMemoryCache(),
})

export async function fetchAPI(query, {
  previewData,
  variables, 
} = {}) {

  const {
    loading,
    error,
    data, 
  } = await apolloClient.query({
    query: gql`${query}`,
    errorPolicy: 'all',
    variables,
  });

  if (data == undefined || data == null) {
    throw new Error('Failed to fetch API');
  }

  return data;
}
